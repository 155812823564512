import React, { useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Container, Row, Col } from 'reactstrap'
import * as Yup from 'yup'

import {
  CheckBoxField, RadioField, OptionField, SingleLineField, SubmitForm
} from '../../../components/FormGroup/FormGroup'
import { Head, Imprint } from '../../../components/Common/CommonComponents'
import {
  SubmissionStatus, postcodeRegex, postTelemetry, yesNoToBool
} from '../../../components/Common/CommonUtils'
import { BaseValues, roles, societies, genderToMFO } from '../Common/SurveyUtils'
import { SurveySubmitButton } from '../Common/SurveyComponents'

import unions from '../unions.json'

const PUBLIC_URL = process.env.PUBLIC_URL || "https://community.peoplesmomentum.com"

interface Values extends BaseValues {
  birthdate: string
  postcode?: string
  labour?: '--' | 'Yes' | 'No'
}

// Stuff that appears on social media previews
const meta = {
  title: 'Momentum Equalities Form',
  url: `${PUBLIC_URL}/equalities`,
  description: 'Equalities monitoring form',
  img: `${PUBLIC_URL}/img/2021-05-28_Banner_Community.png`,
  imgAlt: 'Momentum Community',
}

// Used for our NB tags and logging
const eventCode = 'Equalities Form'

// Components

// Convert form values to the format the backend wants and send it over,
// then post telemetry if successful, and set the submission status
const handleSubmit = (setSubmissionStatus) => async (values: Values) => {
  // I know Formik has its isSubmitting prop but this way I can just use a switch statement
  setSubmissionStatus(SubmissionStatus.Submitting)

  const tags: string[] = []

  // Convert socialist societies to tags
  if (values.socialistSocieties !== undefined) {
    values.socialistSocieties.forEach(society => tags.push(`Socialist Society: ${society}`))
  }

  console.log(values.socialistSocieties)

  const convertedValues = {
    update: {
      email: values.email,
      birthdate: values.birthdate,
      student: yesNoToBool(values.student),
      bame: yesNoToBool(values.bame),
      disabled: yesNoToBool(values.disabled),
      lgbt: yesNoToBool(values.lgbt),
      gender: genderToMFO(values.gender),
      labour: yesNoToBool(values.labour),
      activeInClp: yesNoToBool(values.activeInClp),
      role: values.role,
      union: values.union,
      postcode: values.postcode,
      tags: tags,
    }
  }

  // Send to backend
  const res = await SubmitForm('/update', convertedValues)

  if (res?.ok) {
    postTelemetry(values, eventCode)
    setSubmissionStatus(SubmissionStatus.Success)
  } else {
    setSubmissionStatus(SubmissionStatus.Failure)
  }
}

const Equalities = () => {
  const { email } = useParams()

  const [submissionStatus, setSubmissionStatus] =
    useState<SubmissionStatus>(SubmissionStatus.Unsubmitted)

  const equalitiesSchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required(' (Required)'),
    postcode: Yup.string().uppercase().matches(postcodeRegex, 'Please enter a valid UK postcode')
  })

  return (
    <>
      <Head meta={meta} />

      <Formik
        initialValues={{
          email: email ? decodeURIComponent(email) : '',
          inTradeUnion: undefined,
          union: '',
          student: undefined,
          bame: undefined,
          disabled: undefined,
          lgbt: undefined,
          activeInClp: undefined,
          role: '',
          birthdate: '',
          gender: undefined,
          labour: undefined,
          anySocialistSocieties: undefined,
          socialistSocieties: [],
          postcode: '',
        }}
        validationSchema={equalitiesSchema}
        onSubmit={handleSubmit(setSubmissionStatus)}
      >
        {({ values, isSubmitting, errors }) => (
          <Form>
            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <img
                    src="/img/logo-no-fill-red.svg"
                    style={{display: 'block', width: 400, maxWidth: '100%', margin: '1rem auto 2rem'}}
                  />

                  <h1 style={{textAlign: "center"}}>Equalities Form</h1>

                  <SingleLineField
                    property="email"
                    label="Email"
                    type="email"
                    errors={errors}
                  />
                  <SingleLineField
                    property="postcode"
                    label="Postcode"
                    errors={errors}
                  />

                  <hr />

                  <SingleLineField
                    property="birthdate"
                    label="Date of Birth"
                    type="date"
                    errors={errors}
                  />
                  <OptionField
                    property="gender"
                    label="Which gender do you identify as?"
                    options={['--', 'Female', 'Male', 'Non-Binary/Other']}
                    errors={errors}
                  />
                  <OptionField
                    property="bame"
                    label="Do you consider yourself to be an ethnic minority?"
                    options={["--", "Yes", "No"]}
                    errors={errors}
                  />
                  <OptionField
                    property="disabled"
                    label="Do you consider yourself to have a disability?"
                    options={["--", "Yes", "No", "Prefer not to say"]}
                    errors={errors}
                  />
                  <RadioField
                    property="lgbt"
                    label="Do you identify as LGBTQ+?"
                    options={['Yes', 'No']}
                    errors={errors}
                  />
                  <OptionField
                    property="student"
                    label="Are you a student?"
                    options={["--", "Yes", "No"]}
                    errors={errors}
                  />

                  <hr/>

                  <OptionField
                    property="labour"
                    label="Are you a Labour Party member?"
                    options={["--", "Yes", "No"]}
                    errors={errors}
                  />
                  {
                    values.labour === 'Yes' && (
                      <>
                        <RadioField
                          property="activeInClp"
                          label="Are you active in your Constituency Labour Party (CLP)?"
                          options={['Yes', 'No']}
                          errors={errors}
                        />
                        {
                          (values.labour === 'Yes' && values.activeInClp === 'Yes') && (
                            <OptionField
                              property="role"
                              label="What role do you hold in your CLP?"
                              options={['--', ...roles]}
                              errors={errors}
                            />
                          )
                        }
                      </>
                    )
                  }

                  <RadioField
                    property="inTradeUnion"
                    label="Are you a member of a trade union?"
                    options={['Yes', 'No']}
                    errors={errors}
                  />
                  {
                    values.inTradeUnion === 'Yes' && (
                      <OptionField
                        property="union"
                        label="Which trade union are you a member of?"
                        options={['--', ...unions]}
                        errors={errors}
                      />
                    )
                  }

                  <RadioField
                    property="anySocialistSocieties"
                    label="Are you a member of any socialist societies?"
                    options={['Yes', 'No']}
                    errors={errors}
                  />
                  {
                    values.anySocialistSocieties === 'Yes' && (
                      <>
                        <div className="form-group" style={{marginBottom: 0}}>
                          <label style={{marginBottom: 0}}>
                            Which socialist societies are you a member of?
                          </label>
                        </div>
                        {
                          societies.map(society => (
                              <CheckBoxField
                                property="socialistSocieties"
                                key={society}
                                value={society}
                                label={society}
                                errors={errors}
                              />
                          ))
                        }
                      </>
                    )
                  }

                  <SurveySubmitButton
                    submissionStatus={submissionStatus}
                    errors={errors}
                  />
                </Col>
              </Row>
            </Container>
            { submissionStatus === SubmissionStatus.Success && (
              <Redirect
                push
                to="/equalities/thanks"
              />
            ) }
          </Form>
        )}
      </Formik>
      <Imprint/>
    </>
  )
}

export default Equalities
