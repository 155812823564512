import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import { Imprint } from '../../../components/Common/CommonComponents'

const ThankYou = () => {
  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col sm="12" md="6">
            <h1>Thank You!</h1>
            <p>
              Thanks for telling us more about yourself. We'll be in touch with information on relevant campaigns and how you can get involved soon!
            </p>
          </Col>
        </Row>
      </Container>
      <Imprint/>
    </>
  )
}

export default ThankYou
