// Module compiling all the lobby pages for clarity

import BAMELabour from './BAMELabour/BAMELabour'
import BDS from './BDS/BDS'
import ChildPoverty from './ChildPoverty/ChildPoverty'
import DarkMoney from './DarkMoney/DarkMoney'
import L4L from './L4L/L4L'
import NPF from './NPF/NPF'
import Palestine from './Palestine/Palestine'
import RMT from './RMT/RMT'
import Rwanda from './Rwanda/Rwanda'
import WelfareCuts from './WelfareCuts/WelfareCuts'
import WomensConference from './WomensConference/WomensConference'
import ThankYou from './Common/ThankYou'

const Lobby = {
  BAMELabour,
  BDS,
  ChildPoverty,
  DarkMoney,
  L4L,
  NPF,
  Palestine,
  RMT,
  Rwanda,
  WelfareCuts,
  WomensConference,
  ThankYou,
}

export default Lobby
