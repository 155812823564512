import React from 'react'

import { SubmissionStatus } from '../../../components/Common/CommonUtils'
import { SubmitButton } from '../../../components/FormGroup/FormGroup'

// TODO: This is identical to LobbySubmitButton. Maybe move to CommonComponents?
const buttonText = {
  [SubmissionStatus.Unsubmitted]: 'Send',
  [SubmissionStatus.Submitting]: 'Sending...',
  [SubmissionStatus.Success]: 'Sent!',
  [SubmissionStatus.Failure]: 'Send'
}

const SurveySubmitButton = ({submissionStatus, errors}) => (
  <SubmitButton disabled={[SubmissionStatus.Submitting, SubmissionStatus.Success].includes(submissionStatus)}>
    { buttonText[submissionStatus] }
  </SubmitButton>
)

export { SurveySubmitButton }
