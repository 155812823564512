import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { Container, Row, Col } from 'reactstrap'

import { OptionField, SingleLineField } from '../../../components/FormGroup/FormGroup'
import { Head, Media, OptInField, Imprint } from '../../../components/Common/CommonComponents'
import { SubmissionStatus, MediaType, Values } from '../../../components/Common/CommonUtils'
import {
  LobbyNotice, ConstituencyFinder, FormAnchor, LobbySubmitButton, EmailBodyField, ThanksPageRedirect
} from '../Common/LobbyComponents'
import { handleSubmit } from '../Common/LobbyUtils'

import MPs from '../MPs-2024.json'

const PUBLIC_URL = process.env.PUBLIC_URL || 'https://community.peoplesmomentum.com'

// Page content (this could probably be pushed out into another file)

// Email bodies
// This one is for MPs who've not spoken up yet
const emailForMP = `I am deeply concerned that the Government intends to cut the welfare budget. Any cuts to welfare will exacerbate poverty across the country which is already at record levels.

Instead of slashing welfare for the disabled and long-term sick, we should be taxing the wealthiest in society to fix our creaking public services and reverse years of austerity.

The Labour Party was founded to represent the working class. That includes the sick, unemployed, disabled and most marginalised in society. So as your constituent, I am urging you to vote against any cuts to welfare if they are put to a vote - and to oppose through any other channels.`

// And this one is for people who's MP has spoken up, or isn't a Labour MP
const emailForLiz = `I am deeply concerned that the Government intends to cut the welfare budget. Any cuts to welfare will exacerbate poverty across the country which is already at record levels.

Instead of slashing welfare for the disabled and long-term sick, we should be taxing the wealthiest in society to fix our creaking public services and reverse years of austerity.

The Labour Party was founded to represent the working class. That includes the sick, unemployed, disabled and most marginalised in society. I am urging you, as Secretary of State for Work and Pensions, not to make any cuts to welfare and to focus on genuinely reversing austerity.`

// Generates a footer based on the MP we're emailing and the sender's name
const generateFooter = (vs: Values) => {
  const message = 'Yours sincerely,'
  const senderName = `${vs.givenName} ${vs.familyName}`
  if (senderName.length > 1) {
    return [message, senderName].join('\n\n')
  } else if (vs.labour === 'Yes') {
    return [message, 'A concerned Labour member'].join('\n\n')
  } else {
    return [message, 'A concerned individual'].join('\n\n')
  }
}

// If the MP is in the Labour Party (or "Labour (Co-op)") we should email them
// Otherwise email Liz Kendall
const shouldEmail = (MP: any) => (
  MP && (MP['Party'] === 'Labour' || MP['Party'] === 'Labour (Co-op)')
)

// Returns the relevant email body for the given MP
const getEmailBodyForMP = (MP: any) => {
  if (shouldEmail(MP)) {
    return emailForMP
  } else {
    return emailForLiz
  }
}

const content = {
  // Page
  title: `Tell Your MP: Oppose Welfare Cuts!`,
  intro: `The Labour Government plans to [cut](https://www.theguardian.com/politics/2025/mar/18/key-changes-uk-benefits-cuts-disability-pip-labour) **£5 billion** from benefits for disabled people in the UK. This would be the largest cut in social security for a decade.

Targeting **the most vulnerable** in society is cruel and inhumane. **[More than three quarters](https://www.bigissue.com/news/social-justice/universal-credit-disability-benefits-food-banks)** of people on Universal Credit or disability benefits have gone without **everyday essentials** in the last six months. Appallingly, those with extreme disabilities could end up [poorer](https://www.msn.com/en-gb/money/other/dwp-pip-update-as-freeze-planned-with-those-with-most-extreme-disabilities-set-to-lose-out/ar-AA1ACpu5) after the proposed cuts. Meanwhile, the richest 1% have a combined wealth of [more than 70%](https://scotland.oxfam.org.uk/latest-news/richest-1-grab-nearly-twice-as-much-new-wealth-as-rest-of-the-world-put-together) of the population.

This is more austerity, plain and simple. We need a Labour Government that will stand up for the poorest in society by **implementing wealth taxes** to redistribute wealth.

Use our **lobbying tool** below to **write to your Labour MP** urging them to vote against any cuts to welfare if they go to a vote - and to oppose through any other channels. If you do not have a Labour MP, you can **write to Work and Pensions Secretary** Liz Kendall instead.`,

  // Email
  recipients: (vs: Values) => shouldEmail(vs.MP) ? vs.MP.Email : 'correspondence@dwp.gov.uk',
  subject: 'Oppose Welfare Cuts',
  header: (vs: Values) => `Dear ${vs.MP && shouldEmail(vs.MP) ? vs.MP['Name (Display as)'] : 'Liz'},`,
  body: getEmailBodyForMP,
  footer: generateFooter,

  // Share
  share: (vs: Values) => `I just demanded that ${shouldEmail(vs.MP) ? 'my Labour MP' : 'Liz Kendall'} opposes any planned welfare cuts! Click here to make your voice heard:`,

  // Header
  headerType: MediaType.Image,
  urlOrSlug: '/img/welfare_cuts_banner.png',

  // NB
  eventCode: '2025-03 Lobby Welfare Cuts',
}

// Stuff that appears on social media previews
const meta = {
  title: content.title,
  url: `${PUBLIC_URL}/welfare-cuts?lobby`,
  description: `Use this to demand that Labour MPs oppose any planned welfare cuts!`,
  img: `${PUBLIC_URL}/img/welfare_cuts_banner.png`,
  imgAlt: content.title,
}

const messageText = (MP: any) => {
  if (shouldEmail(MP)) {
    return `Your Labour MP is ${MP?.['Name (Display as)']}. Use our lobbying tool below to demand they oppose any planned welfare cuts.`
  } else {
    return `You don't have a Labour MP. Use our lobbying tool below to demand that Liz Kendall oppose any planned welfare cuts.`
  }
}

const WelfareCuts = () => {
  // I'll remove this later but I'm paranoid about accidentally sending
  // test emails to an actual MP so I need this for peace of mind,
  console.log(process.env.NODE_ENV)

  const [submissionStatus, setSubmissionStatus] =
    useState<SubmissionStatus>(SubmissionStatus.Unsubmitted)

  const lobbySchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required(' (Required)'),
  })

  return (
    <>
      <Head meta={meta} />

      <Media mediaType={content.headerType} urlOrSlug={content.urlOrSlug} alt={meta.imgAlt} />

      <Formik
        initialValues={{
          givenName: '',
          familyName: '',
          email: '',
          mobile: '',
          postcode: '',
          optIn: false,
          labour: undefined,
          constituency: undefined,
          MP: undefined,
          body: '',
        }}
        validationSchema={lobbySchema}
        onSubmit={handleSubmit(setSubmissionStatus, content)}
      >
        {({ values, errors }) => (
          <Form>
            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <ReactMarkdown>{content.intro}</ReactMarkdown>

                  <SingleLineField
                    property="postcode"
                    label="First, enter your UK postcode"
                    errors={errors}
                  />
                  <ConstituencyFinder mps={MPs} body={content.body} newBoundaries />

                  { values.constituency && (
                    <>
                      <div style={{marginBottom: '1rem'}}>
                        { messageText(values?.MP) }
                      </div>
                      <SingleLineField
                        property="givenName"
                        label="First name"
                        errors={errors}
                      />
                      <SingleLineField
                        property="familyName"
                        label="Last name"
                        errors={errors}
                      />
                      <SingleLineField
                        property="email"
                        label="Email address"
                        errors={errors}
                        type="email"
                        required
                      />
                      <SingleLineField
                        property="mobile"
                        label="Mobile phone"
                        errors={errors}
                      />
                      <OptionField
                        property="labour"
                        label="Are you a Labour Party member?"
                        options={['--', 'Yes', 'No']}
                        errors={errors}
                      />
                    </>
                  )}
                  </Col>
                <FormAnchor />
              </Row>
            </Container>

            { values.constituency && (
              <>
                <div className="bg-neutral-200">
                  <Container>
                    <Row className="justify-content-center">
                      <Col sm="12" md="6">
                        <h2>Edit your email</h2>

                        <ReactMarkdown>{content.header(values)}</ReactMarkdown>

                        <EmailBodyField property="body" errors={errors} />

                        <ReactMarkdown>{content.footer(values)}</ReactMarkdown>
                      </Col>
                    </Row>
                  </Container>
                </div>

                <Container>
                  <Row className="justify-content-center">
                    <Col sm="12" md="6">
                      <LobbyNotice
                        errors={errors}
                        submissionStatus={submissionStatus}
                      />

                      <OptInField property="optIn" errors={errors} />

                      <LobbySubmitButton submissionStatus={submissionStatus} errors={errors} />
                    </Col>
                  </Row>
                </Container>

                <Container style={{marginTop: 0, marginBottom: 15}}>
                  <Row className="justify-content-center">
                    <Col sm="12" md="6">
                      * Have we missed something? Let us know at <a href="mailto:info@peoplesmomentum.com">info@peoplesmomentum.com</a>
                    </Col>
                  </Row>
                </Container>
              </>
            ) }

            <ThanksPageRedirect
              submissionStatus={submissionStatus}
              senderName={values.givenName}
              recipientName={
                values.MP && shouldEmail(values.MP) ? (values.MP as any)['Name (Display as)'] : 'Liz Kendall'
              }
              copy={content.share(values)}
            />
          </Form>
        )}
      </Formik>
      <Imprint/>
    </>
  )
}

export default WelfareCuts
