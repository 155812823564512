import React, { useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Container, Row, Col } from 'reactstrap'

import {
  CheckBoxField, RadioField, OptionField, SubmitForm
} from '../../../components/FormGroup/FormGroup'
import { Head, Imprint } from '../../../components/Common/CommonComponents'
import {
  SubmissionStatus, postTelemetry, yesNoToBool
} from '../../../components/Common/CommonUtils'
import { BaseValues, roles, societies, genderToMFO } from '../Common/SurveyUtils'
import { SurveySubmitButton } from '../Common/SurveyComponents'

import unions from '../unions.json'

const PUBLIC_URL = process.env.PUBLIC_URL || 'https://community.peoplesmomentum.com'

interface Values extends BaseValues {
  interestCampaigns: boolean
  interestLocalGovt: boolean
  interestEducation: boolean
  interestUnions: boolean
}

// TypeScript cries about what I'm doing in handleSubmit if I don't do this...
type tagMap = [boolean, string]

// Stuff that appears on social media previews
const meta = {
  title: 'New Member Survey',
  url: `${PUBLIC_URL}/new-member-survey`,
  description: 'Survey for New Members',
  img: `${PUBLIC_URL}/img/2021-05-28_Banner_Community.png`,
  imgAlt: 'Momentum Community',
}

// Used for our NB tags and logging
const eventCode = 'Joiner Survey'

// Components

// Convert form values to the format the backend wants and send it over,
// then post telemetry if successful, and set the submission status
const handleSubmit = (setSubmissionStatus) => async (values: Values) => {
  // I know Formik has its isSubmitting prop but this way I can just use a switch statement
  setSubmissionStatus(SubmissionStatus.Submitting)

  const tags: string[] = []

  // Convert socialist societies to tags
  if (values.socialistSocieties !== undefined) {
    values.socialistSocieties.forEach(society => tags.push(`Socialist Society: ${society}`))
  }

  // Convert areas of work values to tags
  const tagMapping: tagMap[] = [
    [values.interestCampaigns, 'Joiner Survey Interest: Campaigns'],
    [values.interestLocalGovt, 'Joiner Survey Interest: Local Govt'],
    [values.interestEducation, 'Joiner Survey Interest: Education'],
    [values.interestUnions, 'Joiner Survey Interest: Unions'],
  ]
  tagMapping.forEach(tag => tag[0] && tags.push(tag[1]))

  console.log(values.socialistSocieties)

  const convertedValues = {
    update: {
      email: values.email,
      union: values.union,
      student: yesNoToBool(values.student),
      bame: yesNoToBool(values.bame),
      disabled: yesNoToBool(values.disabled),
      lgbt: yesNoToBool(values.lgbt),
      gender: genderToMFO(values.gender),
      activeInClp: yesNoToBool(values.activeInClp),
      role: values.role,
      tags: tags,
    }
  }

  // Send to backend
  const res = await SubmitForm('/update', convertedValues)

  if (res?.ok) {
    postTelemetry(values, eventCode)
    setSubmissionStatus(SubmissionStatus.Success)
  } else {
    setSubmissionStatus(SubmissionStatus.Failure)
  }
}

const JoinerSurvey = () => {
  const { email } = useParams()

  const [submissionStatus, setSubmissionStatus] =
    useState<SubmissionStatus>(SubmissionStatus.Unsubmitted)

  return (
    <>
      <Head meta={meta} />

      <Formik
        initialValues={{
          email: decodeURIComponent(email),
          inTradeUnion: undefined,
          union: '',
          student: undefined,
          bame: undefined,
          disabled: undefined,
          lgbt: undefined,
          activeInClp: undefined,
          gender: undefined,
          role: '',
          anySocialistSocieties: undefined,
          socialistSocieties: [],
          interestCampaigns: false,
          interestLocalGovt: false,
          interestEducation: false,
          interestUnions: false,
        }}
        onSubmit={handleSubmit(setSubmissionStatus)}
      >
        {({ values, isSubmitting, errors }) => (
          <Form>
            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <img
                    src="/img/logo-no-fill-red.svg"
                    style={{display: 'block', width: 400, maxWidth: '100%', margin: '1rem auto 2rem'}}
                  />

                  <h1 style={{marginBottom: '1.2rem'}}>Thanks for joining!</h1>

                  <p>
                    Your payment was set up successfully and you're now a member of Momentum. Joining was the first step. Now it's time to get involved.
                  </p>

                  <p>
                    Please take two minutes to fill in our new members' survey. This helps us to keep our communications relevant to you and your interests.
                  </p>

                  <RadioField
                    property="gender"
                    label="Which gender do you identify as?"
                    options={['Female', 'Male', 'Non-Binary/Other']}
                    errors={errors}
                  />
                  <RadioField
                    property="bame"
                    label="Do you consider yourself to be an ethnic minority?"
                    options={['Yes', 'No']}
                    errors={errors}
                  />
                  <RadioField
                    property="disabled"
                    label="Do you consider yourself to have a disability?"
                    options={['Yes', 'No']}
                    errors={errors}
                  />
                  <RadioField
                    property="lgbt"
                    label="Do you identify as LGBT+?"
                    options={['Yes', 'No']}
                    errors={errors}
                  />
                  <RadioField
                    property="student"
                    label="Are you a student?"
                    options={['Yes', 'No']}
                    errors={errors}
                  />

                  <hr/>

                  <RadioField
                    property="activeInClp"
                    label="Are you active in your Constituency Labour Party (CLP)?"
                    options={['Yes', 'No']}
                    errors={errors}
                  />
                  {
                    values.activeInClp === 'Yes' && (
                      <OptionField
                        property="role"
                        label="What role do you hold in your CLP?"
                        options={['--', ...roles]}
                        errors={errors}
                      />
                    )
                  }
                  <RadioField
                    property="inTradeUnion"
                    label="Are you a member of a trade union?"
                    options={['Yes', 'No']}
                    errors={errors}
                  />
                  {
                    values.inTradeUnion === 'Yes' && (
                      <OptionField
                        property="union"
                        label="Which trade union are you a member of?"
                        options={['--', ...unions]}
                        errors={errors}
                      />
                    )
                  }
                  <RadioField
                    property="anySocialistSocieties"
                    label="Are you a member of any socialist societies?"
                    options={['Yes', 'No']}
                    errors={errors}
                  />
                  {
                    values.anySocialistSocieties === 'Yes' && (
                      <>
                        <div className="form-group" style={{marginBottom: 0}}>
                          <label style={{marginBottom: 0}}>
                            Which socialist societies are you a member of?
                          </label>
                        </div>
                        {
                          societies.map(society => (
                              <CheckBoxField
                                property="socialistSocieties"
                                key={society}
                                value={society}
                                label={society}
                                errors={errors}
                              />
                          ))
                        }
                      </>
                    )
                  }
                  <div className="form-group" style={{marginBottom: 0}}>
                    <label style={{marginBottom: 0}}>
                      Which areas of Momentum's work are you most interested in hearing from us about? (You may tick more than one box.)
                    </label>
                  </div>
                  <CheckBoxField
                    property="interestCampaigns"
                    label="Policy and Political Campaigns, e.g. on free education, public ownership"
                    errors={errors}
                  />
                  <CheckBoxField
                    property="interestLocalGovt"
                    label="Local Government & Municipal Socialism"
                    errors={errors}
                  />
                  <CheckBoxField
                    property="interestEducation"
                    label="Political Education Initiatives"
                    errors={errors}
                  />
                  <CheckBoxField
                    property="interestUnions"
                    label="Trade Union Organising"
                    errors={errors}
                  />

                  <SurveySubmitButton
                    submissionStatus={submissionStatus}
                    errors={errors}
                  />
                </Col>
              </Row>
            </Container>
            {
              submissionStatus === SubmissionStatus.Success && (
                <Redirect
                  push
                  to="/new-member-survey/thanks"
                />
              )
            }
          </Form>
        )}
      </Formik>
      <Imprint/>
    </>
  )
}

export default JoinerSurvey
