// Values common to both the new member survey and the equalities survey
interface BaseValues {
  email: string
  inTradeUnion?: '--' | 'Yes' | 'No'
  union: string
  student?: '--' | 'Yes' | 'No'
  bame?: '--' | 'Yes' | 'No'
  disabled?: '--' | 'Yes' | 'No'
  lgbt?: '--' | 'Yes' | 'No'
  gender?: '--' | 'Female' | 'Male' | 'Non-Binary/Other'
  activeInClp?: '--' | 'Yes' | 'No'
  role?: string
  anySocialistSocieties?: '--' | 'Yes' | 'No'
  socialistSocieties?: string[]
}

// CLP roles (pulled from Labour's CLP resources page)
const roles = [
  'General Member',
  'Chair',
  'Secretary',
  'Treasurer',
  'Membership Officer',
  'Women\'s Officer',
  'Policy Officer',
  'Communications and Social Media Officer',
  'TULO Officer',
  'Political Education & Training Officer',
  'Disability Officer',
  'BAME Officer',
  'Youth Officer',
  'LGBT Officer',
  'Campaign Co-ordinator',
  'Fundraising Co-ordinator',
  'Community Involvement Co-ordinator',
  'Women\'s Co-ordinator',
  'IT Co-ordinator',
  'Membership Recruitment and Retention Co-ordinator',
  'Branch Chair',
  'Branch Secretary',
]

// Socialist societies (placeholders)
const societies = [
  'Fabian Society',
  'National Union of Labour and Socialist Clubs',
  'Society of Labour Lawyers',
  'Labour Students',
  'The Jewish Labour Movement',
  'Socialist Education Association',
  'Socialist Health Association',
  'Christians on the Left',
  'Labour Housing Group',
  'SERA',
  'Labour Irish Society',
  'Scientists for Labour',
  'LGBT Labour',
  'Disability Labour',
  'BAME Labour',
  'Chinese for Labour',
  'Association of Labour Councillors',
  'Young Labour',
  'Labour Finance & Industry Group',
  'Labour Movement for Europe',
  'Labour Animal Welfare Society',
  'Labour Campaign for International Development',
  'Labour Women\'s Network',
]

// Convert "Female" / "Male" / "Non-Binary / Other" / "--" values to M / F / O / undefined, respectively
const genderToMFO = (value: '--' | 'Female' | 'Male' | 'Non-Binary/Other' | undefined) => {
  return value === undefined ? undefined : { Female: 'F', Male: 'M', 'Non-Binary/Other': 'O' }[value]
}

export type { BaseValues }
export { roles, societies, genderToMFO }
