// Common lobby page functions
import { SubmitForm } from '../../../components/FormGroup/FormGroup'
import {
  SubmissionStatus, Values, postTelemetry, yesNoToBool
} from '../../../components/Common/CommonUtils'

// Returns a random int between 0 and n - 1, inclusive
const randInt = (n: number) => Math.floor(Math.random() * n)

// Returns a random element from the given array
const chooseOne = (a: Array<any>) => a[randInt(a.length)]

// If given an array, returns a random element from it. Otherwise just returns what it was given.
const maybeChooseOne = maybeArray => Array.isArray(maybeArray) ? chooseOne(maybeArray) : maybeArray

// If the passed value is a function, call it with the given args, else just return the value
// TODO: There's probably a better way to handle this, but this works for now
// (Sure wish I had Elixir's guard clauses here...)
const maybeEvaluate = (maybeFunction, ...args) => {
  if (maybeFunction instanceof Function) {
    return maybeFunction(...args)
  } else {
    return maybeFunction
  }
}

// Convert form values to the format the backend wants and send it over,
// then post telemetry if successful, and set the submission status
const handleSubmit = (setSubmissionStatus, content) => async (values: Values) => {
  // I know Formik has its isSubmitting prop but this way I can just use a switch statement
  setSubmissionStatus(SubmissionStatus.Submitting)
  const convertedValues = { lobby: {
    sender: {
      email: values.email,
      consents: {
        general: values.optIn ? {
          reason: content.eventCode + ' signup',
          email: true,
          call: true,
          text: true,
          post: true,
          social: true,
        } : undefined
      },
      givenName: values.givenName,
      familyName: values.familyName,
      mobile: values.mobile,
      postcode: values.postcode,
      labour: yesNoToBool(values.labour),
      bame: yesNoToBool(values.bame),
      tags: [content.eventCode],
    },
    recipient: maybeEvaluate(content.recipients, values),
    // JS pipe operator when?
    subject: maybeChooseOne(maybeEvaluate(content.subject, values)),
    content: [content.header(values), values.body, content.footer(values)].join('\n\n'),
  } }

  // Send to backend
  const res = await SubmitForm('/lobby', convertedValues)

  if (res?.ok) {
    postTelemetry(values, content.eventCode)
    setSubmissionStatus(SubmissionStatus.Success)
  } else {
    setSubmissionStatus(SubmissionStatus.Failure)
  }
}

export { maybeEvaluate, handleSubmit }
