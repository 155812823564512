import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { Container, Row, Col } from 'reactstrap'

import { OptionField, SingleLineField } from '../../../components/FormGroup/FormGroup'
import { Head, Media, OptInField, Imprint } from '../../../components/Common/CommonComponents'
import { SubmissionStatus, MediaType, Values } from '../../../components/Common/CommonUtils'
import {
  ThanksPageRedirect, PetitionSubmitButton, PetitionNotice, SignatureCount
} from '../Common/PetitionComponents'
import { handleSubmit } from '../Common/PetitionUtils'

const PUBLIC_URL = process.env.PUBLIC_URL || 'https://community.peoplesmomentum.com'

const content = {
  // Page
  title: `Tell Starmer: Stop the Energy Rip Off`,
  intro: `The energy price cap will [rise](https://news.sky.com/story/energy-bills-for-typical-household-to-rise-to-1-849-a-year-from-april-13316130) to £1,849 for typical households. This means that, on average, energy bills will be almost **£160 more expensive** than last year. Meanwhile, companies continue to make **obscene profits** in the tens of billions on the backs of struggling families.

This cannot continue.

According to a YouGov [survey](https://yougov.co.uk/politics/articles/50098-support-for-nationalising-utilities-and-public-transport-has-grown-significantly-in-last-seven-years) conducted last year, **around 71% of voters** believe energy companies should be run in the public sector. We must put people before profit by backing **[public ownership](https://tribunemag.co.uk/2022/08/nationalise-energy-companies-public-ownership-cost-of-living) of our major energy companies**.

Do you **oppose** the rip off in energy bills? Want to see our energy companies **brought into public ownership**? **Sign our petition**, backed by Labour MPs and organisations such as We Own It and Compass, urging Keir Starmer to take action!`,

  // Share
  share: (vs: Values) => `I just demanded that Labour bring energy companies under public ownership! Click here to make your voice heard:`,

  // Header
  headerType: MediaType.Image,
  urlOrSlug: '/img/nationalise_energy_banner.png',

  // NB
  eventCode: '2025-03 Petition Nationalise Energy',

  // Petition ID
  petitionId: 9,
}

// Stuff that appears on social media previews
const meta = {
  title: content.title,
  url: `${PUBLIC_URL}/nationalise-energy?lobby`,
  description: `Sign our petition to demand that Labour bring energy companies under public ownership!`,
  img: `${PUBLIC_URL}/img/nationalise_energy_banner.png`,
  imgAlt: `Tell Starmer: Stop the Energy Rip Off`,
}

const NationaliseEnergy = () => {
  // I'll remove this later but I'm paranoid about accidentally sending
  // test emails to an actual MP so I need this for peace of mind,
  console.log(process.env.NODE_ENV)

  const [submissionStatus, setSubmissionStatus] =
    useState<SubmissionStatus>(SubmissionStatus.Unsubmitted)

  const lobbySchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required(' (Required)'),
  })

  return (
    <>
      <Head meta={meta} />

      <Media mediaType={content.headerType} urlOrSlug={content.urlOrSlug} alt={meta.imgAlt} />

      <Formik
        initialValues={{
          givenName: '',
          familyName: '',
          email: '',
          mobile: '',
          optIn: false,
          labour: undefined,
          constituency: undefined,
          MP: undefined,
          body: '',
        }}
        validationSchema={lobbySchema}
        onSubmit={handleSubmit(setSubmissionStatus, content)}
      >
        {({ values, errors }) => (
          <Form>
            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <ReactMarkdown>{content.intro}</ReactMarkdown>

                  <SignatureCount petitionId={content.petitionId} />

                  <SingleLineField
                    property="givenName"
                    label="First name"
                    errors={errors}
                  />
                  <SingleLineField
                    property="familyName"
                    label="Last name"
                    errors={errors}
                  />
                  <SingleLineField
                    property="email"
                    label="Email address"
                    errors={errors}
                    type="email"
                    required
                  />
                  <SingleLineField
                    property="mobile"
                    label="Mobile phone"
                    errors={errors}
                  />
                  <OptionField
                    property="labour"
                    label="Are you a Labour Party member?"
                    options={['--', 'Yes', 'No']}
                    errors={errors}
                  />
                  <PetitionNotice
                    errors={errors}
                    submissionStatus={submissionStatus}
                  />

                  <OptInField property="optIn" errors={errors} />

                  <PetitionSubmitButton submissionStatus={submissionStatus} />
                </Col>
              </Row>
            </Container>

            <Container style={{marginTop: 0, marginBottom: 15}}>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  * Have we missed something? Let us know at <a href="mailto:info@peoplesmomentum.com">info@peoplesmomentum.com</a>
                </Col>
              </Row>
            </Container>

            <ThanksPageRedirect
              submissionStatus={submissionStatus}
              senderName={values.givenName}
              copy={content.share(values)}
            />
          </Form>
        )}
      </Formik>
      <Imprint/>
    </>
  )
}

export default NationaliseEnergy
