// Module compiling all the survey pages for clarity

import Equalities from './Equalities/Equalities'
import EqualitiesThankYou from './Equalities/ThankYou'
import JoinerSurvey from './JoinerSurvey/JoinerSurvey'
import JoinerSurveyThankYou from './JoinerSurvey/ThankYou'

const Survey = {
  Equalities,
  EqualitiesThankYou,
  JoinerSurvey,
  JoinerSurveyThankYou,
}

export default Survey
